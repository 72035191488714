import { useState } from 'react';
import { lastDayOfMonth, startOfMonth } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import {
  LocalityJsonldReadLocality,
  StayDateAvailableJsonldReadStayDateAvailableStayType,
} from '@ae/data-access';
import { Locale, useLanguage } from '@ae/i18n';
import { AELink, useGetAELink, useRouter } from '@ae/shared';
import { useTag } from '@ae/tagging';
import { SearchValues } from '../types';
import { useContextualDataLayer } from './useContextualDataLayer';
import { useLocalityInCookie } from './useLocalityInCookie';
import { thematicUrlMatchers, useSearchUrl } from './useSearchUrl';
import { useSearchValuesInCookie } from './useSearchValuesInCookie';

const searchUrlMatchers = {
  [Locale.Fr]: '/recherche',
  [Locale.Nl]: '/zoeken',
  [Locale.En]: '/suchen',
  [Locale.De]: '/search',
};

export const useSearch = ({
  localities,
}: {
  localities: LocalityJsonldReadLocality[] | null | undefined;
}) => {
  const { pathname } = useRouter();
  const { getLinkHref } = useGetAELink();
  const trackEvent = useTag();
  const language = useLanguage();
  const { updateDataLayer, getDataLayer } = useContextualDataLayer();
  const { setLocality: setLocalityInCookie } = useLocalityInCookie();
  const { generateSearchUrl, parseSearchUrl } = useSearchUrl();
  const {
    searchValues: searchValuesFromCookie,
    setSearchValues: setSearchValuesIntoCookie,
  } = useSearchValuesInCookie();
  const [searchValues, setSearchValues] = useState<SearchValues>(() => {
    if (pathname?.includes(searchUrlMatchers[language as Locale])) {
      const searchValues = parseSearchUrl();
      setSearchValuesIntoCookie(searchValues);
      return { ...searchValues };
    }
    // Early booking quick fix
    else if (
      typeof window !== 'undefined' &&
      new URLSearchParams(window.location.href.split('?')[1]).get(
        'utm_campaign'
      ) === 'nc-bimensuelle-2-202411-conversion'
    ) {
      const startDate = toZonedTime(
        new Date('2025-01-01'),
        'Europe/Brussels'
      ).setHours(0, 0, 0, 0);

      return {
        ...searchValuesFromCookie,
        stayDate: {
          startDate: startOfMonth(startDate),
          endDate: lastDayOfMonth(startDate),
          stayType: StayDateAvailableJsonldReadStayDateAvailableStayType.wk,
        },
      };
      // SEO Thematic quick fix
    } else {
      if (pathname.match(thematicUrlMatchers[language as Locale])) {
        const selectedFolderId =
          window?.AEStore?.state?.mr1?.folder?.selectedFolderId;

        searchValuesFromCookie.localityId = selectedFolderId;
      }
      return { ...searchValuesFromCookie };
    }
  });

  const updateSearch = (newSearch: SearchValues) => {
    if (localities && newSearch.localityId !== searchValues.localityId) {
      if (newSearch.localityId) {
        const locality = localities.find((l) => l.id === newSearch.localityId);
        if (locality) {
          setLocalityInCookie(locality);
        }
      } else {
        setLocalityInCookie(null);
      }
    }
    setSearchValues(newSearch);
    updateDataLayer(newSearch);
  };

  const makeSearch = () => {
    trackEvent({
      ga4: {
        event: 'search',
        action: 'submit',
        filter_level: 'mr1',
        ...getDataLayer(searchValues),
      },
    });
    setSearchValuesIntoCookie(searchValues);

    window.location.href = `${getLinkHref(AELink.Search)}${generateSearchUrl(
      searchValues
    )}`;
  };

  return {
    searchValues,
    updateSearch,
    makeSearch,
  };
};
